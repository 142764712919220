import { Flows } from "@Collections/photoFlows";

import Homecover from "./Homecover";
import Orders from "./Orders";

export type Props = {
  flow: string;
};

const NoLongerRequired = ({ flow }: Props) => {
  if (flow === Flows.homecover) {
    return <Homecover />;
  }

  return <Orders />;
};

export default NoLongerRequired;
