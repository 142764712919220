import useTranslation from "next-translate/useTranslation";
import { Grid, H3, Paragraph } from "@boxt/design-system";

import { ReactComponent as PhotosDoneSVG } from "@Images/icons/photos-done.svg";

import { Icon } from "../styles";

const OrdersNoLongerRequired = () => {
  const { t } = useTranslation("photos/homecover/no-longer-required");

  return (
    <>
      <Grid.Row>
        <Grid.Col lg={0} md={0}>
          <Icon>
            <PhotosDoneSVG />
          </Icon>
        </Grid.Col>
        <Grid.Col lg={{ offset: 3, span: 7 }} md={{ offset: 1, span: 6 }}>
          <H3 bottom={4}>{t("title")}</H3>
          <Paragraph bottom={7} size="large">
            {t("sub-title")}
          </Paragraph>
        </Grid.Col>
        <Grid.Col lg={3} md={0} sm={0}>
          <PhotosDoneSVG />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={{ offset: 3, span: 10 }} md={{ offset: 1, span: 6 }} sm={0} />
      </Grid.Row>
    </>
  );
};

export default OrdersNoLongerRequired;
