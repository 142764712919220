import getConfig from "next/config";

import { Products } from "@Collections/products";
import { CreditCheckDecisions, CreditCheckSections } from "@Components/Checkout/stages/types";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import { Flows } from "./photoFlows";

// this file is imported in some server side code and middleware
const boxtCustomerUrl =
  getConfig()?.publicRuntimeConfig?.BOXT_CUSTOMER_SITE_URL || getConfig()?.serverRuntimeConfig?.BOXT_CUSTOMER_SITE_URL;

const LANDING_URLS = {
  [ScreenerVariant.BoilerRepair]: "/boilers/boiler-repair",
  [ScreenerVariant.Heatlink]: "/boilers/boiler-repair/google",
  [ScreenerVariant.HomeCover]: "/home-cover",
  [ScreenerVariant.CalloutInstallation]: "/report-issue",
  [ScreenerVariant.CalloutSubscription]: "/report-issue",
  [ScreenerVariant.BoilerInstallation]: "/boilers",
  [ScreenerVariant.BatteryOnly]: "/home-battery-storage",
};

const BOXT_CUSTOMER_URLS = {
  AC_FINANCE: `${boxtCustomerUrl}/air-conditioning/finance-air-conditioning`,
  BOILER: `${boxtCustomerUrl}/boilers`,
  BOILER_SCREENING: `${boxtCustomerUrl}/boilers/your-home`,
  EXTRAS: `${boxtCustomerUrl}/boilers/[screeningId]/bundles/extras`,
  FINANCE: `${boxtCustomerUrl}/finance`,
  GUIDES: `${boxtCustomerUrl}/guides`,
  HOME: boxtCustomerUrl,
  HOME_COVER: `${boxtCustomerUrl}${LANDING_URLS[ScreenerVariant.HomeCover]}`,
  HOME_COVER_TERMS_AND_CONDITIONS: `${boxtCustomerUrl}/boilers/home-cover-terms-and-conditions`,
  MAGIC_LOGIN: `/magic-login`,
  NOT_FOUND: `${boxtCustomerUrl}/404`,
  PRICE_PROMISE: {
    [Products.Boiler]: `${boxtCustomerUrl}/boilers/the-boxt-price-promise`,
  },
  PRIVACY_POLICY: `${boxtCustomerUrl}/boxt-privacy-policy`,
  RENTAL: `${boxtCustomerUrl}/boilers/subscription`,
  SITEMAP: `/sitemap`,
  SOLAR: `${boxtCustomerUrl}/solar`,
  SOLAR_TERMS_AND_CONDITIONS: `${boxtCustomerUrl}/solar-terms-and-conditions`,
  TERMS_AND_CONDITIONS: `${boxtCustomerUrl}/boxt-legal-terms-and-conditions`,
  TRV_GUIDE: `${boxtCustomerUrl}/boilers/guides/what-are-thermostatic-radiator-valves-and-do-i-need-them`,
};

// Local urls

const API_URLS = {
  ADDRESSES: "/addresses",
  BASKETS: "/baskets",
  BOILER_SUBSCRIPTION: {
    CHECKOUT: "/orders/subscription/checkout",
    CHECKOUT_HS: "/orders/subscription/checkout-hs",
  },
  CREDIT_CHECKS: {
    ADDRESSES: "/credit-checks/addresses",
    CHECK_DECISION: "/credit-checks/[id]/check-decision",
    FINANCIAL_INFO: "/credit-checks/financial-info",
    HIRE_AGREEMENT: "/credit-checks/hire-agreement",
    INSTALLATION_DETAILS: "/credit-checks/installation-details",
    PAYMENT: "/credit-checks/payment-details",
    PERSONAL_DETAILS: "/credit-checks/personal-details",
  },
  CREDIT_CHECKS_V3: {
    ADDRESSES: "/v3/credit-checks/addresses",
    CHECK_DECISION: "/v3/credit-checks/[id]/check-decision",
    CONFIRM_DOCUMENT: "/v3/credit-checks/[id]/confirm-document",
    CREDIT_CHECKS: "/v3/credit-checks/[id]",
    FINANCIAL_INFO: "/v3/credit-checks/financial-info",
    HIRE_AGREEMENT: "/v3/credit-checks/hire-agreement",
    INSTALLATION_DETAILS: "/v3/credit-checks/installation-details",
    PAYMENT: "/v3/credit-checks/payment-details",
    PERSONAL_DETAILS: "/v3/credit-checks/personal-details",
    PRE_CONTRACT_DOCUMENTS: "/v3/credit-checks/pre-contract-documents",
    RESIGN_AGREEMENT: "/v3/credit-checks/resign-agreement",
    RESIGN_OVERVIEW: "/v3/credit-checks/resign-overview",
  },
  DOWNLOAD: "/download-file",
  FINANCE: {
    CALCULATE: "/finance-plans/[id]",
    PLANS: "/finance-plans",
  },
  HOME_COVER: {
    CHECKOUT: "/home-cover/checkout",
    CREATE_SUBSCRIPTION: "/home-cover/create-subscription",
  },
  ORDERS: {
    CHECKOUT: "/orders/checkout",
    INSTALLER: "/orders/installers",
  },
  PROMO_CODE: "/customers/promo-codes",
  SCREENING: "/screeners",
  SCREENING_STATUS: "/screeners/status",
  SOLAR: {
    BASKET: "/solar/screenings/basket",
    ENQUIRY: "/solar/enquiry",
    ESTIMATES: "/solar/estimates",
    INSTALLER: "/solar/installers",
    PACKAGES: "/solar/packages",
  },
  SUBSCRIPTIONS: {
    REFERRAL_CODE: "subscriptions/referrals",
  },
  TRUSTPILOT: {
    REVIEWS: "/trustpilot/reviews",
  },
};

export const getAddToBasketUrl = ({
  productType,
  screeningId,
  params,
  slug,
  isPdpRedirectEnabled,
}: {
  isPdpRedirectEnabled?: boolean;
  productType: Products;
  screeningId: string;
  slug?: string;
  params?: string | URLSearchParams;
}) => {
  switch (productType) {
    case Products.Boiler:
      return isPdpRedirectEnabled && slug
        ? `${ORDER_URLS[productType].PDP.replace("[screeningId]", screeningId).replace("[slug]", slug)}${params || ""}`
        : ORDER_URLS[productType].CONTROLS.replace("[screeningId]", screeningId);
    case Products.HeatPump:
      return `${ORDER_URLS[productType].CHECKOUT.ORDER_SUMMARY.replace("[screeningId]", screeningId)}${params || ""}`;
    default:
      return ORDER_URLS[productType].CHECKOUT.ORDER_SUMMARY.replace("[screeningId]", screeningId);
  }
};

const ORDER_URLS = {
  [Products.BatteryOnly]: {
    CHECKOUT: {
      INSTALLER: "/home-battery-storage/[screeningId]/checkout/installer",
      ORDER_SUMMARY: "/home-battery-storage/[screeningId]/checkout/order-summary",
      PAYMENT: "/home-battery-storage/[screeningId]/checkout/payment",
      VISIT_DATE: "/home-battery-storage/[screeningId]/checkout/visit-date",
      YOUR_DETAILS: "/home-battery-storage/[screeningId]/checkout/your-details",
    },
    LANDING: "/home-battery-storage",
    OPTIONS: "/home-battery-storage/your-options/[screeningId]",
    PDP: "/home-battery-storage/your-options/[screeningId]/[slug]",
    SCREENING: "/home-battery-storage/your-home",
  },
  [Products.Boiler]: {
    CALLOUT: "/boilers/callout",
    CHECKOUT: {
      INSTALLER: "/boilers/[screeningId]/checkout/installer",
      ORDER_SUMMARY: "/boilers/[screeningId]/checkout/order-summary",
      PAYMENT: "/boilers/[screeningId]/checkout/payment",
      VISIT_DATE: "/boilers/[screeningId]/checkout/visit-date",
      YOUR_DETAILS: "/boilers/[screeningId]/checkout/your-details",
    },
    CONTROLS: "/boilers/your-options/[screeningId]/add-ons/controls",
    EXTRAS: "/boilers/your-options/[screeningId]/add-ons/extras",
    INACTIVE_REGION: "/boilers/inactive-region",
    LANDING: "/boilers",
    OPTIONS: "/boilers/your-options/[screeningId]",
    PDP: "/boilers/your-options/[screeningId]/[slug]",
    SCREENING: "/boilers/your-home",
  },
  [Products.BoilerPlan]: {
    CHECKOUT: {
      ORDER_SUMMARY: "/boilers/subscription/checkout-hs/order-summary",
    },
    LANDING: "/boiler-plan/",
    OPTIONS: "/boilers/subscription/your-options",
    PDP: "/boiler-plan/your-options/[screeningId]/[slug]",
  },
  [Products.AC]: {
    CALLOUT: "/air-conditioning/callout",
    CHECKOUT: {
      INSTALLER: "/air-conditioning/[screeningId]/checkout/installer",
      ORDER_SUMMARY: "/air-conditioning/[screeningId]/checkout/order-summary",
      PAYMENT: "/air-conditioning/[screeningId]/checkout/payment",
      VISIT_DATE: "/air-conditioning/[screeningId]/checkout/visit-date",
      YOUR_DETAILS: "/air-conditioning/[screeningId]/checkout/your-details",
    },
    LANDING: "/air-conditioning",
    OPTIONS: "/air-conditioning/your-options/[screeningId]",
    PDP: "/air-conditioning/your-options/[screeningId]/[slug]",
    RAILS_OPTIONS: "/find-ac/[screeningId]/bundles",
    RAILS_PDP: "/find-ac/[screeningId]/bundles/[slug]",
    RAILS_PREFIX: "/find-ac",
    SCREENING: "/air-conditioning/your-home",
  },
  [Products.EV]: {
    CHECKOUT: {
      INSTALLER: "/ev-chargers/[screeningId]/checkout/installer",
      ORDER_SUMMARY: "/ev-chargers/[screeningId]/checkout/order-summary",
      PAYMENT: "/ev-chargers/[screeningId]/checkout/payment",
      VISIT_DATE: "/ev-chargers/[screeningId]/checkout/visit-date",
      YOUR_DETAILS: "/ev-chargers/[screeningId]/checkout/your-details",
    },
    INACTIVE_REGION: "/ev-chargers/inactive-region",
    OPTIONS: "/ev-chargers/your-options/[screeningId]",
    PDP: "/ev-chargers/your-options/[screeningId]/[slug]",
    RAILS_OPTIONS: "/find-an-ev-charger/[screeningId]/bundles",
    RAILS_PDP: "/find-an-ev-charger/[screeningId]/bundles/[slug]",
    RAILS_PREFIX: "/find-an-ev-charger",
    SCREENING: "/ev-chargers/your-home",
  },
  [Products.HeatPump]: {
    CHECKOUT: {
      INSTALLER: "/heat-pumps/[screeningId]/checkout/installer",
      ORDER_SUMMARY: "/heat-pumps/[screeningId]/checkout/order-summary",
      PAYMENT: "/heat-pumps/[screeningId]/checkout/payment",
      VISIT_DATE: "/heat-pumps/[screeningId]/checkout/visit-date",
      YOUR_DETAILS: "/heat-pumps/[screeningId]/checkout/your-details",
    },
    INVALID_REGION: "/heat-pumps/inactive-region",
    LANDING: "/heat-pumps",
    OPTIONS: "/heat-pumps/your-options/[screeningId]",
    PDP: "/heat-pumps/your-options/[screeningId]/[slug]",
    SCREENING: "/heat-pumps/your-home",
    UNABLE_TO_INSTALL: {
      FLAT_ON_ELEVATED_FLOOR: "/heat-pumps/unable-to-install/flat-on-elevated-floor",
      NO_PERMISSION_FOR_FLAT: "/heat-pumps/unable-to-install/no-permission-for-flat",
      NO_ROOM_FOR_CYLINDER: "/heat-pumps/unable-to-install/no-room-for-cylinder",
      NO_SPACE_FOR_OUTDOOR_UNIT: "/heat-pumps/unable-to-install/no-space-for-outdoor-unit",
      NO_VALID_EPC: "/heat-pumps/unable-to-install/no-valid-epc",
    },
  },
  BOOKING: `/orders/[orderId]/confirmation`,
  [Products.HybridHeatPump]: {
    LANDING: "/hybrid-heat-pumps",
  },
};

const GUIDE_URLS = {
  BASE_URL: "/guides",
};

const HUB_URLS = {
  FINANCE: "/finance",
  WHO_WE_ARE: "/who-we-are",
};

const REPAIR_URLS = {
  BOOKING: "/boilers/boiler-repair/[jobId]/confirmation",
  CHECKOUT: {
    PAYMENT: "/boilers/boiler-repair/checkout/payment",
    STAGE: "/boilers/boiler-repair/checkout/[stage]",
    VISIT_DATE: "/boilers/boiler-repair/checkout/visit-date",
    YOUR_DETAILS: "/boilers/boiler-repair/checkout/your-details",
  },
  DETAILS: "/",
  LANDING: "/boilers/boiler-repair",
  OPTIONS: "/boilers/boiler-repair/your-options",
  SCREENING: "/boilers/boiler-repair/your-boiler",
};

const HEATLINK_REPAIR_URLS = {
  BOOKING: "/boilers/boiler-repair/google/[jobId]/confirmation",
  CHECKOUT: {
    STAGE: "/boilers/boiler-repair/google/checkout/[stage]",
    VISIT_DATE: "/boilers/boiler-repair/google/checkout/visit-date",
    YOUR_DETAILS: "/boilers/boiler-repair/google/checkout/your-details",
  },
  LANDING: "/boilers/boiler-repair/google",
  OPTIONS: "/boilers/boiler-repair/google/your-options",
  SCREENING: "/boilers/boiler-repair/google/your-boiler",
};

const PRODUCT_RECALL_URLS = {
  COOKE_LEWIS_GAS_HOB: "/product-recall/cooke-and-lewis-gas-hob",
  INDEX: "/product-recall",
  LANDING: "/product-recall/[...path]",
};

const SERVICE_URLS = {
  BOOK: "/services/[serviceJobId]/book",
  CONFIRMATION: "/services/[serviceJobId]/confirmation",
};

const EV_CHARGER_URLS = {
  LANDING: "/ev-chargers",
  UNABLE_TO_INSTALL: "/ev-chargers/unable-to-install",
  YOUR_HOME: "/ev-chargers/your-home",
  YOUR_OPTIONS: "/ev-chargers/your-options",
};

export const ORDER_MANAGEMENT_URLS = {
  EXPIRED_LINK: "/order-management/expired-link",
  PAYMENT: {
    CONFIRMATION: "/order-management/[orderId]/[paymentStep]/confirmation",
    PAYMENT_STEP: "/order-management/[orderId]/[paymentStep]",
  },
  SAT_NOTE: {
    BASE: "/order-management/[orderId]/sat-note",
    CONFIRMATION: "/order-management/[orderId]/sat-note/thank-you",
  },
};

const SOLAR_URLS = {
  CONFIGURE: {
    BATTERY: "/solar/configure/battery",
    PANELS: "/solar/configure/panels",
    STAGE: "/solar/configure/[stage]",
  },
  CONFIRMATION: "/solar/[jobId]/confirmation",
  ENQUIRY: {
    INSTALLER: "/solar/enquiry/[screeningId]/installer",
    ORDER_SUMMARY: "/solar/enquiry/[screeningId]/order-summary",
    PAYMENT: "/solar/enquiry/[screeningId]/payment",
    STAGE: "/solar/enquiry/[screeningId]/[stage]",
    VISIT_DATE: "/solar/enquiry/[screeningId]/visit-date",
    YOUR_DETAILS: "/solar/enquiry/[screeningId]/your-details",
  },
  INACTIVE_REGION: "/solar/inactive-region",
  LANDING: "/solar",
  SCREENING: "/solar/your-home",
  UNABLE_TO_INSTALL: "/solar/unable-to-install",
};

const BATTERY_ONLY_URLS = {
  INACTIVE_REGION: "/home-battery-storage/inactive-region",
  LANDING: "/home-battery-storage",
  NO_SPACE: "/home-battery-storage/no-space",
  PDP: "/home-battery-storage/your-options/[screeningId]/[slug]",
  PLP: "/home-battery-storage/your-options/[screeningId]",
  SCREENING: "/home-battery-storage/your-home",
};

const CALLOUT_URLS = {
  BOOKING: "/report-issue/[visitId]/booking",
  CONFIRMATION: "/report-issue/[visitId]/confirmation",
  LANDING: "/report-issue",
  REVIEW: "/report-issue/review/[entityId]",
  SCREENING: "/report-issue/issue-type",
};

const SCREENING_URLS = {
  [ScreenerVariant.BoilerRepair]: {
    complete: "/boilers/boiler-repair/your-options",
    start: "/boilers/boiler-repair/your-boiler",
  },
  [ScreenerVariant.Heatlink]: {
    complete: "/boilers/boiler-repair/google/your-options",
    start: "/boilers/boiler-repair/google/your-boiler",
  },
  [ScreenerVariant.HomeCover]: {
    complete: "/home-cover/your-options",
    start: "/home-cover/your-home",
  },
  [ScreenerVariant.HomeServe]: {
    complete: "/cover/your-options",
    start: "/cover/your-home",
  },
  [ScreenerVariant.CalloutInstallation]: {
    complete: CALLOUT_URLS.REVIEW,
    start: CALLOUT_URLS.SCREENING,
  },
  [ScreenerVariant.CalloutSubscription]: {
    complete: CALLOUT_URLS.REVIEW,
    start: CALLOUT_URLS.SCREENING,
  },
  [ScreenerVariant.BoilerSubscription]: {
    complete: "/boilers/subscription/your-options",
    start: "/boilers/subscription/your-home",
  },
  [ScreenerVariant.BoilerInstallation]: {
    complete: "/boilers/your-options/[screeningId]",
    start: "/boilers/your-home",
  },
  [ScreenerVariant.BoilerInstallationExperiment]: {
    complete: "/boilers/your-options/[screeningId]",
    start: "/boilers/your-home",
  },
  [ScreenerVariant.Solar]: {
    complete: "/solar/configure/panels",
    start: "/solar/your-home",
  },
  [ScreenerVariant.EVCharger]: {
    complete: "/ev-chargers/your-options/[screeningId]",
    start: EV_CHARGER_URLS.YOUR_HOME,
  },
  [ScreenerVariant.HeatPump]: {
    complete: ORDER_URLS[Products.HeatPump].OPTIONS,
    start: ORDER_URLS[Products.HeatPump].SCREENING,
  },
  [ScreenerVariant.AirCondition]: {
    complete: ORDER_URLS[Products.AC].OPTIONS,
    start: ORDER_URLS[Products.AC].SCREENING,
  },
  [ScreenerVariant.BatteryOnly]: {
    complete: ORDER_URLS[Products.BatteryOnly].OPTIONS,
    start: ORDER_URLS[Products.BatteryOnly].SCREENING,
  },
};

const HOME_COVER_URLS = {
  BOXT_INSTALL: "/home-cover/boxt-install",
  CANNOT_COVER: {
    FUEL: "/home-cover/cannot-cover/fuel",
    OPTIONS: "/home-cover/cannot-cover/options",
  },
  CHECKOUT: {
    CONFIRMATION: "/home-cover/[subscriptionId]/confirmation",
    CONFIRMATION_WITH_REF: "/home-cover/[subscriptionId]/confirmation?referrer=checkout",
    PAYMENT: "/home-cover/checkout/payment",
    STAGE: "/home-cover/checkout/[stage]",
    YOUR_DETAILS: "/home-cover/checkout/your-details",
  },
  LANDING: "/home-cover",
  OPTIONS: "/home-cover/your-options",
};

const HOME_SERVE_URLS = {
  CALLOUT: "/cover/callout/landlord",
  LANDING: "/cover",
  OPTIONS: "/cover/your-options",
  SCREENING: "/cover/your-home",
};

const BOILER_CALLOUT_URLS = {
  LANDING: "/boilers/callout",
  OIL: "/boilers/callout/oil",
};

const BOILER_SUBSCRIPTION_URLS = {
  ADDITIONAL_INFO: "/boilers/subscription/additional-information",
  CALLOUT: "/boilers/subscription/callout",
  CALLOUT_OIL: "/boilers/subscription/callout/oil",
  CHECKOUT: {
    ADDRESS_HISTORY: "/boilers/subscription/checkout/address-history",
    ORDER_SUMMARY: "/boilers/subscription/checkout/order-summary",
    [CreditCheckSections.FINANCIAL_INFO]: "/boilers/subscription/checkout/financial-info",
    [CreditCheckSections.PAYMENT_DETAILS]: "/boilers/subscription/checkout/payment",
    [CreditCheckSections.INSTALLATION_DETAILS]: "/boilers/subscription/checkout/visit-date",
    [CreditCheckSections.PERSONAL_DETAILS]: "/boilers/subscription/checkout/your-details",
    [CreditCheckSections.ADDRESSES]: "/boilers/subscription/checkout/installation-address",
    [CreditCheckSections.HIRE_AGREEMENT]: "/boilers/subscription/checkout/hire-agreement",
    STAGE: "/boilers/subscription/checkout/[stage]",
    [CreditCheckSections.CONFIRMATION]: "/boilers/subscription/[orderId]/confirmation",
  },
  CHECKOUT_HS: {
    ADDRESS_HISTORY: "/boilers/subscription/checkout-hs/address-history",
    ORDER_SUMMARY: "/boilers/subscription/checkout-hs/order-summary",
    [CreditCheckSections.FINANCIAL_INFO]: "/boilers/subscription/checkout-hs/financial-info",
    [CreditCheckSections.PAYMENT_DETAILS]: "/boilers/subscription/checkout-hs/payment",
    [CreditCheckSections.INSTALLATION_DETAILS]: "/boilers/subscription/checkout-hs/visit-date",
    [CreditCheckSections.PERSONAL_DETAILS]: "/boilers/subscription/checkout-hs/your-details",
    [CreditCheckSections.ADDRESSES]: "/boilers/subscription/checkout-hs/installation-address",
    [CreditCheckSections.PRE_CONTRACT_DOCUMENTS]: "/boilers/subscription/checkout-hs/pre-contract-documents",
    [CreditCheckSections.HIRE_AGREEMENT]: "/boilers/subscription/checkout-hs/hire-agreement",
    STAGE: "/boilers/subscription/checkout-hs/[stage]",
    [CreditCheckSections.CONFIRMATION]: "/boilers/subscription/[orderId]/confirmation",
  },
  DECISION_HS: {
    [CreditCheckDecisions.REFERRED]: "/boilers/subscription/additional-information-hs",
    [CreditCheckDecisions.DECLINED]: "/boilers/subscription/declined-hs",
  },
  LANDING: "/boilers/subscription",
  OPTIONS: "/boilers/subscription/your-options",
  PDP: "/boilers/subscription/your-options/[slug]",
  REFERRED_CHECKOUT: {
    CHECKOUT: "/boilers/subscription/referred/checkout",
    NEXT_STEPS: "/boilers/subscription/referred/checkout/next-steps",
    STAGE: "/boilers/subscription/referred/checkout/[stage]",
    [CreditCheckSections.HIRE_AGREEMENT]: "/boilers/subscription/referred/checkout/hire-agreement",
    [CreditCheckSections.PAYMENT_DETAILS]: "/boilers/subscription/referred/checkout/payment",
    [CreditCheckSections.CONFIRMATION]: "/boilers/subscription/[orderId]/confirmation",
  },
  REFERRED_CHECKOUT_HS: {
    CHECKOUT: "/boilers/subscription/referred-hs/checkout",
    NEXT_STEPS: "/boilers/subscription/referred-hs/checkout/next-steps",
    STAGE: "/boilers/subscription/referred-hs/checkout/[stage]",
    [CreditCheckSections.PRE_CONTRACT_DOCUMENTS]: "/boilers/subscription/referred-hs/checkout/pre-contract-documents",
    [CreditCheckSections.HIRE_AGREEMENT]: "/boilers/subscription/referred-hs/checkout/hire-agreement",
    [CreditCheckSections.PAYMENT_DETAILS]: "/boilers/subscription/referred-hs/checkout/payment",
    [CreditCheckSections.CONFIRMATION]: "/boilers/subscription/[orderId]/confirmation",
  },
  RE_SIGN_AGREEMENT_HS: {
    NEXT_STEPS: "/boilers/subscription/re-sign-agreement/next-steps",
    ORDER_SUMMARY: "/boilers/subscription/re-sign-agreement/order-summary",
    [CreditCheckSections.HIRE_AGREEMENT]: "/boilers/subscription/re-sign-agreement/hire-agreement",
    [CreditCheckSections.CONFIRMATION]: "/boilers/subscription/[orderId]/confirmation",
  },
  SCREENING: "/boilers/subscription/your-home",
  [CreditCheckDecisions.REFERRED]: "/boilers/subscription/additional-information",
  [CreditCheckDecisions.DECLINED]: "/boilers/subscription/declined",
};

const PHOTO_URLS = {
  BASE: "/photos",
  [Flows.orders]: ORDER_URLS.BOOKING,
  [Flows.homecover]: HOME_COVER_URLS.CHECKOUT.CONFIRMATION,
  [Flows.repairs]: REPAIR_URLS.BOOKING,
  [Flows.callouts]: CALLOUT_URLS.REVIEW,
  [Flows.rental]: BOILER_SUBSCRIPTION_URLS.CHECKOUT[CreditCheckSections.CONFIRMATION],
  [Flows.solar]: SOLAR_URLS.CONFIRMATION,
  HOME_COVER_PHOTO_UPLOAD: "/photos/homecover/[id]/upload-photos",
};

export {
  API_URLS,
  BATTERY_ONLY_URLS,
  BOILER_CALLOUT_URLS,
  BOILER_SUBSCRIPTION_URLS,
  BOXT_CUSTOMER_URLS,
  boxtCustomerUrl,
  CALLOUT_URLS,
  EV_CHARGER_URLS,
  GUIDE_URLS,
  HEATLINK_REPAIR_URLS,
  HOME_COVER_URLS,
  HOME_SERVE_URLS,
  HUB_URLS,
  LANDING_URLS,
  ORDER_URLS,
  PHOTO_URLS,
  PRODUCT_RECALL_URLS,
  REPAIR_URLS,
  SCREENING_URLS,
  SERVICE_URLS,
  SOLAR_URLS,
};
