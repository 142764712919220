const landing = {
  guidesList: {
    search: {
      button: "guides-list-search-button",
      input: "guides-list-search-input",
    },
  },
  postcode: {
    button: "postcode-button",
    input: "postcode-input",
  },
};

export default landing;
