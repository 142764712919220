import type { AxiosRequestConfig, GenericAbortSignal } from "axios";
import { AxiosError } from "axios";
import axios from "axios";

import Bugsnag from "@Lib/bugsnag";

const nextApi = axios.create({
  baseURL: "/api/_next",
  withCredentials: true,
});

nextApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.code === AxiosError.ERR_NETWORK) {
      // report network error to Bugsnag
      // so it wont think that it's not handled
      return Bugsnag.notify(error, (event) => {
        event.severity = "info";
      });
    }
    return Promise.reject(error);
  },
);
const fetcher = async (url: string, params: AxiosRequestConfig["params"] = {}, signal?: GenericAbortSignal) => {
  const { data } = await nextApi.get(url, { params, signal });
  return data;
};

export { fetcher, nextApi as NextApi };
export default nextApi;
