"use client";

import styled from "styled-components";
import { breakpoint, colors, H4, Paragraph, rem } from "@boxt/design-system";

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

const getBackgroundColor = (isDragAccept, isDragReject) => {
  if (isDragReject) {
    return `${colors.issue}`;
  }

  if (isDragAccept) {
    return "rgba(0, 165, 111, 0.1)";
  }

  return "rgba(157, 176, 197, 0.1)";
};

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isDragAccept", "isDragReject"].includes(prop),
})<{
  isDragAccept?: boolean;
  isDragReject?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border: 1px dashed ${colors.slate.lighter};
  border-radius: 6px;
  background: ${({ isDragAccept, isDragReject }) => getBackgroundColor(isDragAccept, isDragReject)};
  outline: none;
  transition: border 0.24s ease-in-out;
  position: relative;
  overflow: hidden;
`;

export const Copy = styled(Paragraph)`
  display: none;

  ${breakpoint("md")} {
    display: block;
  }
`;

export const SmallTitle = styled(H4)`
  display: block;

  ${breakpoint("md")} {
    display: none;
  }
`;

export const LargeTitle = styled(H4)`
  display: none;

  ${breakpoint("md")} {
    display: block;
  }
`;

export const ErrorMessage = styled(Paragraph)`
  display: flex;
  align-items: top;

  svg {
    width: ${rem(20)};
    flex: 0 0 auto;
    height: ${rem(20)};
    margin-top: ${rem(3)};
  }
`;
