"use client";

import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const RequirementsContainer = styled.div`
  ${includeSpacing({ mb: 3 })};
`;

export const RequirementsList = styled.div`
  ${includeSpacing({ mb: 2 })};
`;
