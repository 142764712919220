"use client";

import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const PhotosListContainer = styled.ul`
  ${includeSpacing({ mt: { lg: 2, md: 2, sm: 4 } })};
`;

export default PhotosListContainer;
