"use client";

import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const PreviewContainer = styled.div`
  border-top: 1px solid ${colors.platinum.light};
  padding-top: ${rem(24)};
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  max-width: ${rem(220)};
  margin: 0 auto;
  display: block;
  text-align: center;

  ${breakpoint("md")} {
    max-width: ${rem(300)};
  }

  ${breakpoint("lg")} {
    max-width: ${rem(300)};
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
`;

export const RemovePreview = styled.div<{ $isDisabled: boolean }>`
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0 : 1)};
`;
