import type { Address } from "@Collections/types/address";
import type { Cost } from "@Collections/types/boxtCosts";
import type { CalloutLineItem } from "@Collections/types/callout/lineItem";
import type { Customer } from "@Collections/types/customer";

// callout job returned from index endpoint
export interface CalloutJobIndex {
  createdAt: string;
  customer: Customer;
  customerName: string;
  formattedAddress: string;
  id: string;
  jobAddress: Address;
  notes: string;
  outcome: CalloutJobOutcome;
  paymentOutstanding: boolean;
  productType: string;
  region: Region;
  screenerDerivedUrgency: boolean;
  shortId: string;
  state: CalloutJobState;
  type: CalloutType;
  urgent: boolean;
}

export interface CalloutJobShow extends CalloutJobIndex {
  boxtAdditionalCosts: Cost[];
  costsOwedToEngineer: Cost[];
  lineItems: CalloutLineItem[];
  meta: Record<string, any>;
  paidCustomerCostsInPence: number;
  planName: string;
  subscriptionGcReference: string;
  subscriptionId: string;
  subscriptionReference: string;
  subscriptionShortId: string;
  totalBoxtCostInPence: number;
  totalCustomerCostInPence: number;
  totalNetCosts: number;
  totalNetCostsOwedToEngineer: number;
  totalOriginalCostInPence: number;
  totalVatCosts: number;
  visits: Visit[];
}

export interface Visit {
  appointmentTime: string;
  id: string;
  shortId: string;
  state: string;
  visitType: string;
  report?: string;
  audit?: string;
}

export enum CalloutType {
  Installation = "BoxtEngine::Callouts::InstallationJob",
  HomeCover = "BoxtEngine::Callouts::SubscriptionJob",
}

interface Configuration {
  active: boolean;
  associatedPackagesIds: string[];
  productType: string;
  regionalFeeInPence: number;
  supplierId: string;
}

interface Region {
  active: boolean;
  artificial: boolean;
  configuration: Configuration[];
  postcodes: string[];
  serviceRegionId: string | null;
  title: string;
  updatedAt: string;
}

export enum CalloutJobState {
  Open = "open",
  Closed = "closed",
  Rejected = "rejected",
  Cancelled = "cancelled",
  AwaitingReview = "awaiting_review",
}

export enum CalloutJobOutcome {
  IssueFixed = "issue_fixed",
  RepairRequired = "repair_required",
  ReplacementRequired = "replacement_required",
  CannotDiagnose = "cannot_diagnose",
  AwaitingInspection = "awaiting_inspection",
  AwaitingCustomerBooking = "awaiting_customer_booking",
  FixedAfterFollowUp = "fixed_after_follow_up",
  CannotFix = "cannot_fix",
}

export enum SubscriptionStates {
  Active = "active",
  InIssue = "in_issue",
  ReadyForReview = "ready_for_review",
  SignedUp = "signed_up",
  Cancelled = "cancelled",
}

/**
 * Type for subscription object returned inside /customers/callouts/orders/{short_id}
 * @link https://api.boxt-staging.com/api-docs/index.html?urls.primaryName=API%20V2%20Docs
 */
export type CalloutOrderSubscription = {
  gcSubscriptionId: string;
  gcSubscriptionStatus: string;
  id: string;
  planId: string;
  planName: string;
  startDate: string;
  status: SubscriptionStates;
};
