import type { ImageLoaderProps, ImageProps } from "next/legacy/image";
import NextImage from "next/legacy/image";

import type { ImgixParams } from "./imgix-loader";
import imgixLoader from "./imgix-loader";

const Image = ({ quality = 80, imgixParams, ...rest }: ImageProps & ImgixParams) => {
  const isSVG = typeof rest.src === "string" && rest.src.includes(".svg");

  return (
    <NextImage
      loader={(props: ImageLoaderProps) => imgixLoader({ imgixParams, ...props })}
      quality={quality}
      unoptimized={isSVG}
      {...rest}
    />
  );
};

export default Image;
