"use client";

import styled from "styled-components";
import { colors, includeSpacing, rem } from "@boxt/design-system";

export const UploadFailedContainer = styled.div`
  border-top: 1px solid ${colors.platinum.light};
  padding-top: ${rem(24)};
`;

export const RetryUpload = styled.div`
  ${includeSpacing({ mb: 5, mh: "auto" })};
  padding: 0 ${rem(24)};
  max-width: ${rem(480)};
`;
