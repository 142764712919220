import useTranslation from "next-translate/useTranslation";
import { BulletPoints, Grid, H3, H4, H5, Paragraph } from "@boxt/design-system";

import { ReactComponent as BulletSVG } from "@Images/icons/bullet.svg";
import { ReactComponent as PhotosDoneSVG } from "@Images/icons/photos-done.svg";

import { Icon } from "../styles";

type WhatNext = {
  item: string;
  key: string;
};

const OrdersNoLongerRequired = () => {
  const { t } = useTranslation("photos/orders/no-longer-required");

  const whatNext: WhatNext[] = t("what-next", {}, { returnObjects: true });

  return (
    <>
      <Grid.Row>
        <Grid.Col lg={0} md={0}>
          <Icon>
            <PhotosDoneSVG />
          </Icon>
        </Grid.Col>
        <Grid.Col lg={{ offset: 3, span: 7 }} md={{ offset: 1, span: 6 }}>
          <H3 bottom={4}>{t("title")}</H3>
          <Paragraph bottom={7} size="large">
            {t("sub-title")}
          </Paragraph>
          <H4 bottom={3}>{t("what-next-title")}</H4>
          <Paragraph bottom={6}>{t("what-next-copy")}</Paragraph>
          <H5 bottom={3}>{t("prepare-installation")}</H5>
          <BulletPoints icon={<BulletSVG />} items={whatNext} />
        </Grid.Col>
        <Grid.Col lg={3} md={0} sm={0}>
          <PhotosDoneSVG />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col lg={{ offset: 3, span: 10 }} md={{ offset: 1, span: 6 }} sm={0} />
      </Grid.Row>
    </>
  );
};

export default OrdersNoLongerRequired;
