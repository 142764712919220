import type { Flows } from "@Collections/photoFlows";
import type { Products } from "@Collections/products";
import type { Photo } from "@DataAccess/local/customers/orders/photos";

import Item from "./components/Item";
import { PhotosListContainer } from "./styles";

export type Props = {
  photos: Photo[];
  flow: Flows;
  productType: Products;
};

const PhotosList = ({ photos, flow, productType }: Props) => (
  <PhotosListContainer>
    {photos.map((photo) => (
      <Item flow={flow} key={photo.photoKey} photo={photo} productType={productType} />
    ))}
  </PhotosListContainer>
);

export default PhotosList;
