import useTranslation from "next-translate/useTranslation";
import { Button, H4 } from "@boxt/design-system";

import type { PhotoPreviewT } from "@Components/pages/photos/PhotoUpload/types";

import { ImageContainer, ImagePreview, PreviewContainer, RemovePreview } from "./styles";

export type Props = {
  isUploading: boolean;
  onRemovePreview: () => void;
  previewPhotos: PhotoPreviewT[];
  i18nNamespace: string;
};

const Preview = ({ previewPhotos, onRemovePreview, isUploading, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <PreviewContainer>
      <H4 align="center" bottom={6}>
        {t("preview-photo")}
      </H4>
      <ImageContainer>
        {previewPhotos.map((photo) => (
          <ImagePreview alt="" key={photo.lastModified} src={photo.preview} />
        ))}
        <RemovePreview $isDisabled={isUploading}>
          <Button disabled={isUploading} onClick={onRemovePreview} size="small" skin="plain">
            {t("remove")}
          </Button>
        </RemovePreview>
      </ImageContainer>
    </PreviewContainer>
  );
};

export default Preview;
