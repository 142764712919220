import Link from "next/link";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { Button, H4 } from "@boxt/design-system";

import getPathAndSearchParamsFromRouter from "@Helpers/getPathAndSearchParamsFromRouter";
import getI18nNamespace from "@Helpers/photos/getI18nNamespace";
import { Flows } from "@Collections/photoFlows";
import type { Products } from "@Collections/products";
import type { Photo } from "@DataAccess/local/customers/orders/photos";
import PhotoUploadStatus from "@Components/pages/photos/PhotoUpload/constants";
import { uploadPhotos } from "@Locators";
import { ReactComponent as CameraSVG } from "@Images/icons/camera.svg";
import { ReactComponent as PencilSVG } from "@Images/icons/pencil.svg";

import { getPhotoIcon } from "./helpers";
import { FullButton, Icon, IconButton, PhotoDetails, PhotoItem, Span } from "./styles";

export type Props = {
  photo: Photo;
  flow: Flows;
  productType: Products;
};

const Item = ({ photo, flow, productType }: Props) => {
  const { t } = useTranslation(getI18nNamespace({ flow, productType }).photos);
  const router = useRouter();
  const { plainUrl, searchParams } = getPathAndSearchParamsFromRouter(router);

  const isUploaded = photo.status === PhotoUploadStatus.Uploaded;
  const isOptional = photo.optional;

  const buttonSkin = isUploaded ? "plain" : "primary";
  const buttonTheme = flow === Flows.rental ? "coral" : "jade";
  const buttonText = isUploaded ? t("change-photo") : t("upload-photo");
  const PhotoIcon = getPhotoIcon(photo.status);

  return (
    <li>
      <Link href={`${plainUrl}/${photo.photoKey}${searchParams}`} legacyBehavior passHref>
        <PhotoItem $status={photo.status}>
          <PhotoDetails>
            <Icon $isUploaded={isUploaded}>
              <PhotoIcon />
            </Icon>
            <H4>
              {t(`${photo.key}.title`, { radiatorNumber: photo.photoKey.split("-")[1] })}
              {isOptional && <Span>{t("optional-photo")}</Span>}
            </H4>
          </PhotoDetails>
          <IconButton>
            <Button
              as="div"
              boxtTheme={buttonTheme}
              icon={{
                afix: "center",
                size: "large",
                svg: isUploaded ? <PencilSVG /> : <CameraSVG />,
              }}
              skin={buttonSkin}
            />
          </IconButton>
          <FullButton>
            <Button
              as="div"
              boxtTheme={buttonTheme}
              skin={buttonSkin}
              testId={`${uploadPhotos.uploadItem}${photo.photoKey}`}
            >
              {buttonText}
            </Button>
          </FullButton>
        </PhotoItem>
      </Link>
    </li>
  );
};

export default Item;
