"use client";

import styled from "styled-components";
import tinycolor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

import type { PhotoStatus } from "@DataAccess/local/customers/orders/photos";
import PhotoUploadStatus from "@Components/pages/photos/PhotoUpload/constants";

export const PhotoItem = styled.a<{ $status: PhotoStatus }>`
  background-color: ${({ $status }) =>
    $status === PhotoUploadStatus.Rejected ? tinycolor(colors.coral.dark).setAlpha(0.05).toRgbString() : colors.white};
  ${includeSpacing({ mb: { lg: 1, md: 1, sm: 2 } })};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  text-decoration: none;
  align-items: center;
`;

export const Icon = styled.div<{ $isUploaded: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  ${includeSpacing({ mr: 2 })};
  color: ${({ $isUploaded }) => ($isUploaded ? colors.jade.normal : colors.coral.normal)};
  flex-shrink: 0;
`;

export const PhotoDetails = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${rem(10)};
`;

export const FullButton = styled.div`
  display: none;

  ${breakpoint("md")} {
    display: block;
    flex-shrink: 0;
  }
`;

export const IconButton = styled.div`
  div {
    padding: 0;
    width: 3rem;
    height: 3rem;
  }

  ${breakpoint("md")} {
    display: none;
  }

  svg {
    color: transparent;
  }
`;

export const Span = styled.span`
  margin-left: 0.5rem;
  line-height: 1.5rem;
  font-size: ${rem(16)};
  font-weight: 400;
`;
