import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid, H3, Paragraph, Spacing } from "@boxt/design-system";

import { ButtonWrapper, Container, PhotosDoneVerticalSVG } from "./styles";

type Props = {
  i18nNamespace: string;
  url?: string;
};

const Common = ({ url, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <Grid.Row as={Container}>
      <Grid.Col lg={{ offset: 3, span: 10 }} md={{ offset: 1, span: 6 }}>
        <H3 align="center" bottom={5}>
          {t("title")}
        </H3>
        <Trans
          components={{
            br: <br />,
            p: <Paragraph align="center" bottom={7} />,
            s: <strong />,
          }}
          i18nKey={`${i18nNamespace}:text`}
        />
        {url ? (
          <>
            <ButtonWrapper>
              <Link href={url as string} legacyBehavior passHref>
                <Button boxtTheme="jade" data-testid="cta-button" size="medium">
                  {t("button")}
                </Button>
              </Link>
            </ButtonWrapper>
            <Spacing mb={{ md: 5, sm: 4 }} />
          </>
        ) : null}
        <PhotosDoneVerticalSVG />
      </Grid.Col>
    </Grid.Row>
  );
};

export default Common;
