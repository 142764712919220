"use client";

import styled from "styled-components";
import { colors, includeSpacing } from "@boxt/design-system";

export const ImageExamples = styled.div`
  display: flex;
`;

export const ImageExampleContainer = styled.div`
  ${includeSpacing({ mb: 1 })};
`;

export const ImageExamplesRow = styled.div`
  flex: 1;
`;

export const PhotoTextContainer = styled.div<{ $example: string }>`
  display: flex;
  align-items: top;

  svg {
    color: ${({ $example }) => ($example === "good" ? colors.jade.normal : colors.coral.dark)};
    flex: 0 0 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }

  h5 {
    color: ${colors.coal.lighter};
  }
`;
