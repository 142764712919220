import useSWR from "swr";

import { Flows } from "@Collections/photoFlows";
import { CALLOUT_URLS, HOME_COVER_URLS, REPAIR_URLS } from "@Collections/routes";
import type { CalloutJobShow, Visit } from "@Collections/types/callout/job";
import { CalloutJobOutcome } from "@Collections/types/callout/job";
import { fetcher } from "@DataAccess/local";

import Common from "./Common";
import PhotosCompletedExtendedCopy from "./PhotosCompletedExtendedCopy";

type Props = {
  flow: Flows;
  id: string;
};

const getUrl = (flow: Flows, id: string | undefined): string | undefined => {
  if (id) {
    if (flow === Flows.repairs) {
      return REPAIR_URLS.BOOKING.replace("[jobId]", id);
    }

    if (flow === Flows.homecover) {
      return HOME_COVER_URLS.CHECKOUT.CONFIRMATION.replace("[subscriptionId]", id);
    }

    if (flow === Flows.callouts) {
      return CALLOUT_URLS.BOOKING.replace("[visitId]", id);
    }
  }
};

const Completed = ({ flow, id }: Props) => {
  const { data } = useSWR<CalloutJobShow | null>(flow === Flows.callouts ? `/callouts/jobs/${id}` : null, fetcher);
  let entityId: string | undefined;
  let i18nNamespace: string = `photos/${flow}/completed`;

  if (flow === Flows.callouts) {
    const visit: Visit | undefined = data?.visits?.[0];
    entityId = visit?.id;

    if (visit && !visit.appointmentTime && data?.outcome === CalloutJobOutcome.AwaitingCustomerBooking) {
      i18nNamespace = `${i18nNamespace}-book`;
    } else if (visit && visit.appointmentTime) {
      i18nNamespace = `${i18nNamespace}-view`;
    }
  } else {
    entityId = id;
  }

  if ([Flows.repairs, Flows.homecover, Flows.callouts].includes(flow)) {
    return <Common i18nNamespace={i18nNamespace} url={getUrl(flow, entityId)} />;
  }

  return <PhotosCompletedExtendedCopy flow={flow} />;
};

export default Completed;
