import useTranslation from "next-translate/useTranslation";
import { Button, H4 } from "@boxt/design-system";

import { RetryUpload, UploadFailedContainer } from "./styles";

export type Props = {
  i18nNamespace: string;
  onRetryUpload: () => void;
};

const UploadFailed = ({ onRetryUpload, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <UploadFailedContainer>
      <H4 align="center" bottom={6} boxtTheme="error">
        {t("upload-failed")}
      </H4>
      <RetryUpload>
        <Button boxtTheme="jade" isFullWidth onClick={onRetryUpload} size="medium">
          {t("try-again")}
        </Button>
      </RetryUpload>
    </UploadFailedContainer>
  );
};

export default UploadFailed;
