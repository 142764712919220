import useTranslation from "next-translate/useTranslation";
import { H4 } from "@boxt/design-system";

import getI18nNamespace from "@Helpers/photos/getI18nNamespace";
import type { Flows } from "@Collections/photoFlows";
import type { Products } from "@Collections/products";
import type { Photo } from "@DataAccess/local/customers/orders/photos";

import PhotoDetailsAndUpload from "./components/PhotoDetailsAndUpload";
import { Container, PhotoHeader, Span } from "./styles";
import type { PhotoPreviewT } from "./types";

export type Props = {
  hasFailedUploading: boolean;
  isUploading: boolean;
  onFileSelection: (files: PhotoPreviewT[]) => void;
  onRemovePreview: () => void;
  onRetryUpload: () => void;
  photo: Photo;
  flow: Flows;
  previewPhotos: PhotoPreviewT[];
  productType: Products;
};

const PhotoUpload = ({
  hasFailedUploading,
  isUploading,
  onFileSelection,
  onRemovePreview,
  onRetryUpload,
  photo,
  flow,
  previewPhotos,
  productType,
}: Props) => {
  const nameSpace = getI18nNamespace({ flow, productType }).photos;
  const { t } = useTranslation(nameSpace);
  const { key, photoKey } = photo;
  const isOptional = photo.optional;
  return (
    <Container>
      <PhotoHeader>
        <H4>
          {t(`${key}.title`, { radiatorNumber: photoKey.split("-")[1] })}
          {isOptional && <Span>{t("optional-photo")}</Span>}
        </H4>
      </PhotoHeader>
      <PhotoDetailsAndUpload
        flow={flow}
        hasFailedUploading={hasFailedUploading}
        i18nNamespace={nameSpace}
        isUploading={isUploading}
        onFileSelection={onFileSelection}
        onRemovePreview={onRemovePreview}
        onRetryUpload={onRetryUpload}
        photoKey={key}
        previewPhotos={previewPhotos}
      />
    </Container>
  );
};

export default PhotoUpload;
