const yourBoiler = {
  faultCode: {
    continue: "fault-code-continue",
    input: "fault-code-input",
    skip: "fault-code-skip",
  },
  make: {
    input: "boiler-make-input",
    item: "boiler-make-item--", // data-test=boiler-make-item--${camelCase(name)}
    moreButton: "boiler-make-more-button",
  },
};

export default yourBoiler;
