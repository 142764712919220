const uploadPhotos = {
  backToPhotoTask: "upload-photos-back-to-photo-task-button",

  dragZone: "upload-photos-drag-zone",

  // can be upload || upload and finish
  finish: "finish-button",

  // data-test=`upload-photos-button-${value}`
  selectPhoto: "upload-photos-select-button",

  uploadAction: "upload-photos-action-button",
  uploadItem: "upload-photos-item-button--",
};

export default uploadPhotos;
